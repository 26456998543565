// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.qrcode-container {
    margin-left: 45px;
    text-align: center;
    background-color: $button-color;
    border-radius: 30px;
    transition: 0.2s ease-out;
    width: 400px;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.qrcode-container:hover {
    background-color: $button-hover-color;
}

.link-mostrar {
    text-decoration: none;
    color: $link-clor;
    font-weight: bold;
}

.link-mostrar:hover {
    color: $link-clor-hover;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.visible {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@media (max-width: 768px) {
    .qrcode-container {
        margin-left: 0px;
    }

    .tamanho-img {
        width: 200px;
    }
}