.textArea-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.section-style-textArea {
    display: flex;
    margin-bottom: 12px;
    background-color: white;
    border-bottom: 2px solid gray;
    border-radius: 4px;
    padding: 0 2px;

    &.border-gray {
        border-color: gray;
    }

    &.border-red {
        border-color: red;
    }

    &.border-green {
        border-color: green;
    }
}

.text-textArea {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
    width: 420px;
    height: 200px;
    min-width: 100px;
    min-height: 100px;
    max-width: 520px;
    max-height: 300px;
}

.text-coment-textArea {
    color: red;
}

.fading-container-textArea {
    transition: opacity 0.7s ease-in-out;
    max-width: 270px;
    ;
}

@media (max-width: 768px) {
    .text-textArea {
        width: 200px;
        height: 100px;
        min-width: 100px;
        min-height: 100px;
        max-width: 420px;
        max-height: 200px;
    }
}