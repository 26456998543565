// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.area-divider {
    height: 2px;
    background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    width: 55%;
    max-width: 850px;
}