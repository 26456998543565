// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.container {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
}

.container-cadastro {
    display: flex;
}

.card-base-cadastro {
    display: flex;
    align-items: center;
    margin-inline: 10px;
    align-items: flex-start;
    transition: 0.2s ease-out;
}

.btn-container-register {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .container-cadastro {
        flex-direction: column;
    }
}