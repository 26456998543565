// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.visit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $button-color;
    border-radius: 30px;
    width: 400px;
    opacity: 0;
    transition: 0.2s ease-out;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.visible {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.visit-container:hover {
    background-color: $button-hover-color;
}

.graph-size {
    width: 160px;
    height: 160px;

}

.list-remove {
    list-style-type: none;
}