@import '../../utils/variables';

.edit-user-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
}

.edit-user-info-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
}

.input-container-perfil {
    display: flex;
    justify-content: center;
}

.edit-user-info-buttons {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .edit-user-info-form {
        max-width: 100%;
    }
}