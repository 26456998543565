// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.background-cover {
    display: flex;
    flex-direction: row;
    margin-inline: auto;
    max-width: 1100px;
    width: 80%;
    background-image: url("../../img/cover.jpg");
    background-size: cover;
    transform: skewY(-3deg);
    transform-origin: top left;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 100px;
}

.lado-direito{
    margin-left: auto;
}

.cover-info {
    text-align: center;
    width: 45%;
    background-color: $back-tranparent-color;
    padding: 20px;
    padding-left: 50px;
    border-bottom-left-radius: 100px;
}

.titulo-click {
    color: $light-color;
    cursor: pointer;
}

.cover-info p {
    color: $light-color;
}

@media (max-width: 768px) {
    .cover-info {
        width: 100%;
        border-bottom-right-radius: 30%;
    }

    .background-cover {
        width: 95%;
    }
}