.funcLink-container {
  text-align: center;
}

.container-card {
  display: flex;
}

.card-base {
  height: 100px;
  width: 300px;
  display: flex;
  align-items: center;
  margin: 25px;
  transition: 0.2s ease-out;
}

.card-base:hover {
  transform: scale(1) translate(1px, -10px);
}

.img-res {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.area-text {
  width: 200px;
}

@media (max-width: 768px) {
  .container-card {
    flex-direction: column;
  }
}