// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';


.img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img-edit-perfil {
    background-color: $button-hover-color;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    object-fit: cover;

    &.disable{
        cursor: not-allowed;
    }
}

.img-btn-container {
    @extend .img-container;
    flex-direction: row;
}