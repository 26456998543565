$primary-color: #602e9d;
$primary-color-hover: #543d6d;
$back-tranparent-color: rgba(108, 79, 139, 0.4);
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
$secondary-color: gray;
$light-color: white;
$dark-color: #38393c;
$button-color: #ebebeb;
$button-hover-color: #DEDEDE;
$transparent-color: rgba(0, 0, 0, 0);
$back-full-page: #F2F2F2;
$link-clor: blue;
$link-clor-hover: darkblue;