// Importa o arquivo de variáveis SCSS, onde são definidos valores reutilizáveis como cores e tamanhos
@import './utils/variables';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  background-color: $back-full-page;
}

.app {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Altura mínima de 100% da viewport */
}

.main-content {
    flex: 1; /* Permite que o conteúdo principal expanda */
}