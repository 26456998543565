// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: $light-color;
  border-radius: 8px;
  width: 500px;
  max-height: 70vh;
  box-shadow: $box-shadow;
  position: relative;
  display: flex;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 25px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.modal-body {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}