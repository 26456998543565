// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.button {
    color: $light-color;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: $primary-color;
    cursor: pointer;
    transition: background-color 0.3s ease;



    &.button:hover {
        background-color: $primary-color-hover;
        color: $light-color;
    }

    &:disabled,
    &:disabled:hover {
        background-color: #cccccc;
        cursor: not-allowed;
    }
}


.secondary {
    background-color: $secondary-color;
}

.loading {
    background-color: #6c757d;

    .spinner {
        margin-inline: auto;
        border: 4px solid $button-hover-color;
        border-top: 4px solid $primary-color;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}