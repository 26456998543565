// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/_variables.scss';

.not-found-link {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.buttom-link {
    font-size: 16px;
    border: none;
    border-radius: 4px;
    color: $light-color;
    text-decoration: none;
    background-color: $primary-color;
    transition: background-color 0.3s ease;

    &.buttom-link:hover {
        background-color: $primary-color-hover;
        color: $light-color;
    }
}