// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.container-code {
    display: flex;
    justify-content: center;
    margin: 0 20px;
}

.section-style-code {
    margin-bottom: 12px;
    background-color: $light-color;
    width: 35px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid $secondary-color;
    margin: 0 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.input-field-code {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    text-align: center;
    font-size: 20px;
    color: black;
}

.input-field-code:focus {
    outline: none;
}