// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.rodape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $button-color;
}

.Links-midea {
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
}

.creditos {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-top: 1px solid #cbd0d3;
}

.copyright {
    margin-left: 10px;
}

.card-base-midia {
    font-weight: lighter;
    display: flex;
    align-items: center;
    text-align: center;
}

.img-res-git {
    width: 45px;
}

.area-text-midea {
    width: 100px;
}

.rm-decoration {
    text-decoration: none;
}

@media (max-width: 768px) {
    .creditos {
        flex-direction: column;
    }

    .copyright {
        margin-left: none;
    }
}