// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.container-link {
    display: flex;
    align-items: center;
    text-align: center;
}

.input-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.input-text {
    font-size: 12pt;
    border-radius: 4px;
    width: 270px;
    height: 40px;
    border: 2px solid $light-color;
    transition: border-color 0.3s ease;
}

.input-text:focus {
    border-color: $primary-color;
}

@media (max-width: 768px) {
    .container-link {
        flex-direction: column;
    }

    .right {
        margin-top: 30px;
    }
}