// Importa o arquivo de variáveis globais para garantir consistência e reutilização de estilos em todo o projeto.
@import '../../utils/variables';

.error-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;


    h2 {
        margin-bottom: 15px;
        font-size: 1.5rem;
        color: #333;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .select-report {
        width: 100px;
        height: 30px;
    }

    .btn-container-report {
        display: flex;
        flex-direction: row;
    }
}